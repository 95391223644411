import { gql } from "@apollo/client";

export const PaymentQuery = gql`
  query Payments(
    $page: Int
    $status: [String]
    $paymentType: String
    $createdAt: [BulkPaymentFilter_createdAt]
    $order: [BulkPaymentFilter_order]
  ) {
    bulkPayments(
      status_code_list: $status
      page: $page
      paymentType_code: $paymentType
      createdAt: $createdAt
      order: $order
    ) {
      paginationInfo {
        lastPage
      }
      collection {
        id
        status {
          code
        }
        paymentTransactionId
        amount
        createdAt
        paymentType {
          code
        }
      }
    }
  }
`;
