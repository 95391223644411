import moment from "moment";
import React from "react";
import { Payment } from "../../Interfaces/Payment";

import * as TransactionCardStyle from "./TransactionCard.module.scss";

interface Props {
  data: Payment;
}

const Index = ({ data }: Props) => {
  return (
    <div className={TransactionCardStyle.card}>
      <div className={`${TransactionCardStyle.card__header} grediant1`}>
        <h6 className="boldText textMd">Dump Text</h6>
      </div>
      <div className={TransactionCardStyle.card__content}>
        <div className={TransactionCardStyle.card__content__item}>
          <span>Date</span>
          <span>{moment(data.createdAt).format("DD/MM/YYYY hh:mm")}</span>
        </div>
        <div className={TransactionCardStyle.card__content__item}>
          <span>Amount</span>
          <span>&#8377;{data.amount}</span>
        </div>
        <div className={TransactionCardStyle.card__content__item}>
          <span>Order no.</span>
          <span>{data.paymentTransactionId}</span>
        </div>
        <div className={TransactionCardStyle.card__content__item}>
          <span>Status</span>
          <span>{data.status.code}</span>
        </div>
        <div className={TransactionCardStyle.card__content__item}>
          <span>Purchase</span>
          <span>{data.paymentType.code}</span>
        </div>
      </div>
    </div>
  );
};

export default Index;
