import React, { useEffect, useState } from "react";

import { useState as useHookState } from "@hookstate/core";
import { gql, useQuery } from "@apollo/client";

import Loader from "../Loader";
import TransactionCard from "../TransactionCard";
import Modal from "../Modal";
import TransactionFilter from "../Modals/TransactionFilter";

import AppState from "../../States/AppState";

import { PaymentQuery } from "../../Query/Payment";

import Logger from "../../utils/Logger";
import { popoverAlert } from "../../utils/Alert";
import { getFormattedDate } from "../../utils/FormatTime";

import { GetParkingAreaQuery } from "../../Interfaces/ParkingArea";
import { BulkPaymentQuery, Payment } from "../../Interfaces/Payment";

import FilterIcon from "../../images/filter_icon2@2x.png";

import * as TransactionStyle from "./Transaction.module.scss";

interface FilterData {
  paymentType?: "PRIVATE_INVITATION" | "EVENT" | "ALL";
  fromDate?: Date;
  toDate?: Date;
  parkingArea?: string;
}

const GetAreas = gql`
  query ParkingAreas($status: [String], $category: [String]) {
    parkingAreas(status_code_list: $status, category_code_list: $category) {
      collection {
        id
        name
        category {
          code
        }
      }
    }
  }
`;

const Index = () => {
  const userId = useHookState(AppState).user.get()?.id;

  const [filter, setFilter] = useState<FilterData | undefined>();
  const [isFilter, setIsFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [transactions, setTransactions] = useState<Payment[]>();

  const { data, error, loading, fetchMore, refetch } =
    useQuery<BulkPaymentQuery>(PaymentQuery, {
      variables: {
        status: ["SUCCESS"],
        order: [{ createdAt: "DESC" }],
        category: ["PARKING", "CHARGING"],
      },
    });

  const {
    data: parkingAreasData,
    error: parkingAreaError,
    loading: parkingAreaLoading,
  } = useQuery<GetParkingAreaQuery>(GetAreas, {
    variables: {
      user: userId,
      status: ["ACTIVE", "DEACTIVE"],
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (error) {
      popoverAlert({ msg: error.message, icon: "error" });
    }
    if (parkingAreaError) {
      popoverAlert({ msg: parkingAreaError.message, icon: "error" });
    }
  }, [error, parkingAreaError]);

  useEffect(() => {
    if (data?.bulkPayments.collection) {
      setTransactions(
        transactions
          ? [...transactions, ...data.bulkPayments.collection]
          : data.bulkPayments.collection
      );
    }
  }, [data]);

  useEffect(() => {
    fetchMore({
      variables: {
        page: 1,
        paymentType:
          filter?.paymentType === "ALL" ? undefined : filter?.paymentType,
        createdAt: (filter?.fromDate || filter?.toDate) && [
          {
            before: filter?.fromDate
              ? getFormattedDate(filter.fromDate.toISOString())
              : undefined,
            after: filter?.toDate
              ? getFormattedDate(filter.toDate.toISOString())
              : undefined,
          },
        ],
      },
    })
      .then((res) => {
        setTransactions(res.data.bulkPayments.collection);
      })
      .catch((err) => {
        Logger.showError(err);
      });
  }, [filter]);

  return (
    <>
      <div className={TransactionStyle.container}>
        <div className={TransactionStyle.header}>
          <h5 className="color-primary boldText textMd">Transaction</h5>
          {transactions?.length && (
            <button onClick={() => setIsFilter(true)}>
              <img src={FilterIcon} alt="filter" width={32} height={32} />
            </button>
          )}
        </div>
        <div className={TransactionStyle.content}>
          {!transactions?.length && !loading && (
            <h5 className="color-primary boldText textMd">No Transaction</h5>
          )}
          {transactions?.map((payment) => (
            <TransactionCard data={payment} />
          ))}
        </div>
        {data?.bulkPayments &&
          data?.bulkPayments.paginationInfo.lastPage > page && (
            <div className={TransactionStyle.loadMore}>
              <button
                onClick={() => {
                  fetchMore({
                    variables: {
                      page: page + 1,
                      paymentType:
                        filter?.paymentType === "ALL"
                          ? undefined
                          : filter?.paymentType,
                      createdAt: (filter?.fromDate || filter?.toDate) && [
                        {
                          before: filter?.fromDate
                            ? getFormattedDate(filter.fromDate.toISOString())
                            : undefined,
                          after: filter?.toDate
                            ? getFormattedDate(filter.toDate.toISOString())
                            : undefined,
                        },
                      ],
                    },
                  })
                    .then((res) => {
                      setPage(page + 1);
                      setTransactions(
                        transactions
                          ? [
                              ...transactions,
                              ...res.data.bulkPayments.collection,
                            ]
                          : res.data.bulkPayments.collection
                      );
                    })
                    .catch((err) => {
                      Logger.showError(err);
                    });
                }}
              >
                Load More
              </button>
            </div>
          )}
      </div>

      {isFilter && (
        <Modal
          title="Filter"
          showHeader
          isOpen={isFilter}
          onClose={() => setIsFilter(false)}
        >
          <TransactionFilter
            data={filter}
            parkingAreas={parkingAreasData?.parkingAreas.collection}
            onClose={() => setIsFilter(false)}
            onSubmit={(data) => {
              setPage(1);
              setFilter(data);
              setTransactions(undefined);
              setIsFilter(false);
            }}
          />
        </Modal>
      )}
      <Loader isLoading={loading || parkingAreaLoading} />
    </>
  );
};

export default Index;
