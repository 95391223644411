import React from "react";

import Layout from "../Components/Layout";
import Transaction from "../Components/Transaction";

const TransactionPage = () => {
  return (
    <Layout isPrivate showHeader>
      <Transaction />
    </Layout>
  );
};

export default TransactionPage;
