import moment from "moment";
import React, { useState } from "react";

import DatePicker from "react-datepicker";
import { useForm, SubmitHandler } from "react-hook-form";

import { ParkingArea } from "../../../Interfaces/ParkingArea";
import { getFormattedDate } from "../../../utils/FormatTime";

import * as TransactionFilterStyle from "./TransactionFilter.module.scss";

interface FilterData {
  paymentType?: "PRIVATE_INVITATION" | "EVENT" | "ALL";
  fromDate?: Date;
  toDate?: Date;
  parkingArea?: string;
}

interface Props {
  data?: FilterData;
  parkingAreas?: ParkingArea[];
  onSubmit: (data: FilterData | undefined) => void;
  onClose: () => void;
}

const Index = ({ data, parkingAreas, onClose, onSubmit }: Props) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      parkingArea: data?.parkingArea || undefined,
      paymentType: data?.paymentType || undefined,
    },
  });
  const [fromDate, setFromDate] = useState<Date | undefined>(
    data?.fromDate ? moment(data?.fromDate).toDate() : undefined
  );
  const [toDate, setToDate] = useState<Date | undefined>(
    data?.toDate ? moment(data?.toDate).toDate() : undefined
  );

  const onhandleSubmit: SubmitHandler<FilterData> = (formData) => {
    onSubmit({
      fromDate: fromDate,
      toDate: toDate,
      ...formData,
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onhandleSubmit)}
      className={TransactionFilterStyle.filter}
    >
      <div>
        <button
          className={`${TransactionFilterStyle.btn} ${TransactionFilterStyle.btn__clear}`}
          type="button"
          onClick={() => onSubmit(undefined)}
        >
          Clear All
        </button>
      </div>
      <div className={TransactionFilterStyle.filter__item}>
        <select placeholder="Select Parking Area" {...register("parkingArea")}>
          <option value="">All</option>
          {parkingAreas?.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </select>
      </div>
      <div className={TransactionFilterStyle.filter__item}>
        <DatePicker
          selected={fromDate}
          selectsStart
          dateFormat="dd-MM-yyyy"
          onChange={(date) => {
            if (date) setFromDate(date);
          }}
          placeholderText="From Date"
        />
        <DatePicker
          selected={toDate}
          selectsStart
          dateFormat="dd-MM-yyyy"
          onChange={(date) => {
            if (date) setToDate(date);
          }}
          placeholderText="To Date"
        />
      </div>
      <div className={TransactionFilterStyle.filter__item}>
        <select {...register("paymentType")}>
          <option value="ALL">All</option>
          <option value="PRIVATE_INVITATION">Corporate Invitation</option>
          <option value="EVENT">Parking Invitation</option>
        </select>
      </div>
      <div className={TransactionFilterStyle.filter__btns}>
        <button
          className={`${TransactionFilterStyle.btn} ${TransactionFilterStyle.btn__close}`}
          type="button"
          onClick={onClose}
        >
          Close
        </button>
        <button
          className={`${TransactionFilterStyle.btn} ${TransactionFilterStyle.btn__save}`}
          type="submit"
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default Index;
